<template>
  <div :class="headerMenuClass"
       class="header-menu"
  >
    <slot />
    <q-popover class="header-menu__popover"
               fit
    >
      <q-list>
        <q-item v-for="(link, idx) in childLinks"
                :key="idx"
                :to="link.to"
        >
          {{ link.label }}
        </q-item>
      </q-list>
    </q-popover>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu',
  props: {
    routeName: {
      type: String,
      default: '',
    },
    childLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isCurrentRoute: false,
    };
  },
  computed: {
    headerMenuClass() {
      return {
        'header-menu--active': this.isCurrentRoute,
      };
    },
  },
  watch: {
    $route(to, from) {
      this.isCurrentRoute = to.matched.find(match => match.name === this.routeName) ? true : false;
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.header-menu.header-menu--active, .header-menu.header-menu--active:hover
  background-color white
  color black
  font-weight 700

.header-menu__popover
  box-shadow $shadow-elevation-3
  .q-list
    padding 0
    background-color initial
    .q-item
      padding 16px
      color $dark
      text-transform uppercase
      letter-spacing 0.3px
      font-weight 700
      font-size 1em
</style>
