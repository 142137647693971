<template>
  <q-layout class="base-layout">
    <Header />
    <q-page-container class="page-container">
      <router-view />
    </q-page-container>
    <LaunchAnimation v-if="!devMode && !animationOver"
                     @animationOver="animationOver = true" />
    <InfoBanner v-if="devMode"
                :message="devMessage" />
  </q-layout>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/app/components/layout/header/header.vue';
import LaunchAnimation from '@/app/pages/launch-animation';
import InfoBanner from '@/app/components/layout/info-banner.vue';

export default {
  components: {
    Header,
    LaunchAnimation,
    InfoBanner,
  },
  data() {
    return {
      animationOver: false,
      devMessage:
        "You're running Oasis in Dev Mode, don't forget to set the mode false (boolean property) in the store to switch on Production Mode",
    };
  },
  computed: {
    ...mapState('global', ['devMode']),
  },
  created() {
    if (this.$store.state.user?.user?.featuresRights?.data?.READ) {
      this.$store.dispatch('building/fetch');
    }
    if (this.$store.state.user?.user?.featuresRights?.oapp?.READ) {
      this.$store.dispatch('oapp/fetch');
    }
    if (this.$store.state.user?.user?.featuresRights?.sum?.READ) {
      this.$store.dispatch('sum/fetchSums'); // to call when navigating to graph
    }
  },
};
</script>

<style lang="stylus" scoped>
.base-layout, .page-container
  overflow-x hidden
  min-height auto
  height 100%
</style>
