<template>
  <div class="start-animation">
    <svg class="svg-logo"
         :style="svgLogoStyle"
         viewBox="0 0 100 100">
      <path class="svg-logo__a"
            :style="svgLogoAStyle"
            d="M60,36 L76,65 L44,65 Z" />
      <circle cx="39.2%"
              cy="50%"
              r="16%"
              class="svg-logo__o" />
      <circle cx="50%"
              cy="50%"
              r="48%"
              class="circle-container" />
    </svg>
    <div class="loader-title">
      OASIS
    </div>
    <q-progress :percentage="loaderPercentage"
                color="white"
                style="height: 5px" />
  </div>
</template>
<script>
import sceneMixin from '@/app/mixins/scene';

export default {
  name: 'AnimationLogo',
  mixins: [sceneMixin],
  data() {
    return {
      loaderIntervalID: null,
      animationIntervalID: null,
      loaderPercentage: 0,
      loadingDuration: 3500, //in milliseconds
      svgLogo: {
        opacity: 0,
        blur: 7,
      },
      svgLogoA: {
        strokeOffset: 45,
      },
    };
  },
  computed: {
    svgLogoStyle() {
      return {
        opacity: this.svgLogo.opacity,
        filter: `blur(${this.svgLogo.blur}px)`,
      };
    },
    svgLogoAStyle() {
      return {
        'stroke-dashoffset': `${this.svgLogoA.strokeOffset}vw`,
      };
    },
  },
  mounted() {
    this.startLoader();
    this.startAnimation();
  },
  beforeDestroy() {
    clearInterval(this.animationIntervalID);
  },
  methods: {
    startAnimation() {
      // animate te A triangle
      this.animationIntervalID = setInterval(() => {
        this.svgLogoA.strokeOffset -= 5;
        if (this.svgLogoA.strokeOffset < -1000) this.svgLogoA.strokeOffset = 45;
      }, 350);
      // show the logo after 400ms
      this.at(400, (this.svgLogo.opacity = 1));
      // remove blur filter after 700ms
      this.at(700, (this.svgLogo.blur = 0));
    },
    startLoader: function() {
      this.loaderIntervalID = setInterval(() => {
        if (this.loaderPercentage < 120) this.loaderPercentage++;
        else this.loadisFinished();
      }, this.loadingDuration / 100);
    },
    loadisFinished() {
      clearInterval(this.loaderIntervalID);
      this.svgLogo.opacity = 0;
      this.svgLogo.blur = 7;
      this.at(1000, () => this.$emit('animationOver'));
    },
  },
};
</script>
<style lang="stylus" scoped>
@import '~variables'

.start-animation
  position fixed
  top 0
  left 0
  z-index 45000
  width 100%
  height 100%
  background-color black
  opacity 1
  transition 2s linear
  .svg-logo
    z-index 200
    margin auto
    margin-top 6%
    margin-right 40vw
    margin-left 40vw
    width 20vw
    height 20vw
    filter blur(7px)
    opacity 0
    transition 0.9s ease-in-out
    transform scale(0.7)
  .circle-container
    fill none
    stroke white
    stroke-width 2px
  .svg-logo__a
    transition stroke-dashoffset 0.5s linear
    fill none
    stroke white
    stroke-width 2px
    stroke-dashoffset 45vw
    stroke-dasharray 160
    stroke-linecap round
  .svg-logo__o
    opacity 1
    fill none
    stroke white
    stroke-width 2px
  .loader-title
    display flex
    justify-content center
    color white
    letter-spacing 3px
    font-weight 300
    font-size 5rem
  .q-progress
    position absolute
    bottom 0px
    display block
    overflow hidden
    width 100%
    background-clip padding-box
</style>
