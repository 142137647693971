export default {
  methods: {
    at(time = Number, func = Function) {
      setTimeout(func, time);
    },
    read(content = Array, speed = Number) {
      let frame = 0;
      content.forEach(el => {
        frame += +speed;
        this.at(frame, el);
      });
    },
    loop(content = Array, speed = Number) {
      const max = content.length;
      let frame = 0;
      const interval = setInterval(function() {
        frame++;
        if (frame >= max) {
          frame = 0;
        }
        content[frame]();
      }, speed);

      return interval;
    },
  },
};
