<template>
  <QModal v-model="show"
          class="dashboard-modal"
          @show="focusDashboardNameField"
          @hide="emitClose">
    <div class="dashboard-modal__header">
          {{ $t('pages.dashboard.popUp.create') }}
    </div>
    <div class="dashboard-title">
      <form
        id="addDashboardForm"
        @submit.prevent="submitForm"
      >
        <text-field
          ref="textField"
          v-model.trim="$v.dashboardName.$model"
          :label="$t('pages.dashboard.addDashboard.enterDashboardName')"
          :placeholder="$t('pages.dashboard.addDashboard.dashboardName')"
          :errorMessage="errorMessage"
        />
        <input
          type="submit"
          hidden
        />
      </form>
    </div>
    <div class="create-button">
      <button
        form="addDashboardForm"
        type="submit"
        :disabled="$v.dashboardName.$invalid"
      >
        {{ $t('pages.dashboard.addDashboard.createDashboard') }}
      </button>
    </div>
  </QModal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import TextField from '@/app/components/inputs/text-field.vue';

export default {
  name: 'AddDashboard',
  components: { TextField },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  validations: {
    dashboardName: {
      required,
      isUnique(value) {
        return !this.checkIfDashboardNameExists();
      }
    },
  },
  data() {
    return {
      dashboardName: '',
    };
  },
  computed: {
    errorMessage() {
      if (!this.$v.dashboardName.$error) {
        return '';
      }
      if (!this.$v.dashboardName.required) {
        return this.$t('pages.dashboard.addDashboard.emptyDashboardName');
      } else if (!this.$v.dashboardName.isUnique) {
        return `${this.$t('pages.dashboard.addDashboard.dashboardNameExist')} ${this.dashboardName}`;
      }
      // this should never be reached
      return '';
    }
  },
  methods: {
    submitForm() {
      this.$v.$touch(); // trigger form validation if nothing has been touched, ex: user pressed 'enter' immediately.
      if (this.$v.$invalid) {
        return ;
      }
      this.emitMainClick();
    },
    emitClose() {
      this.dashboardName = '';
      // somehow we need to call $v.$reset in nextTick() for it to work
      // https://github.com/vuelidate/vuelidate/issues/132#issuecomment-402082549
      this.$nextTick(() => {
        this.$v.$reset();
      });
      this.$emit('close');
    },
    emitMainClick() {
      this.$emit('mainClick', this.dashboardName);
    },
    /**
     * Returns true is there is already a dashboard named this.dashboardName, else false.
     */
    checkIfDashboardNameExists() {
      const dashboards = this.$store.state.dashboard.collection;
      const presentDashboardNames = dashboards.map(dashboard => dashboard.name);
      return presentDashboardNames.includes(this.dashboardName);
    },
    focusDashboardNameField() {
      this.$refs.textField.$refs.nameValue.focus();
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.dashboard-modal
  font-size $fs-h1
  padding 10px
  .dashboard-modal__header
    display flex
    flex-direction column
    overflow hidden
    padding 16px
    display grid
    place-items center
    font-size $fs-h1
    font-weight bold
  .dashboard-title
    flex-direction column
    padding $space-2
  .create-button
    width 200px
    margin-left auto
    padding-right 10px
</style>
