<template>
  <q-layout-header class="header">
    <div class="header__left"
         @wheel.prevent="">
      <router-link v-if="user"
:to="{name: user.defaultPage}"
                   class="link--no-underline"
                   @click.native="clearCurrentBuilding">
        <OasisLogo />
      </router-link>
    </div>
    <div v-if="$q.platform.is.mobile && selectedBuilding"
         class="header__info">
      <div class="float-left header__info__building-name">
        {{ selectedBuilding.name }}
      </div>
      <div class="float-right floor-level__label"
           @click="clearCurrentBuilding">
        {{ currentFloorName }}
      </div>
    </div>
    <div v-if="$q.platform.is.desktop"
         class="header__center"
         @wheel.prevent="">
      <router-link v-if="user && dataRights.READ"
                   :to="{name:'data'}"
                   class="header__link t_header--data"
                   exact>
        {{$t('pages.data.tabTitle')}}
      </router-link>
      <header-menu v-if="user && oqlRights.READ"
                   :childLinks="oqlMenu"
                   :class="{'router-link-active': $route.name === 'builder' || $route.name === 'query'}"
                   class="header__link t_header--oql"
                   routeName="oql">
        OQL
      </header-menu>
      <router-link v-if="user && ticketRights.READ"
                   :to="{name:'ticket'}"
                   class="header__link t_header--ticket"
                   exact>
        ticket
      </router-link>
      <DashboardPopUp v-if="user && dashboardRights.READ"
                    class="header__link t_header--dashboard"
                    :class="{'router-link-active': $route.name === 'dashboard'}"/>
      <router-link
        :to="{ name: 'reports' }"
        class="header__link t_header--reports"
        activeClass="router-link-active"
      >
        Reports
      </router-link>
      <router-link v-if="user && devicesControlRight.READ"
                   :to="{ name:'devices' }"
                   class="header__link"
      >
        Devices
      </router-link>
    </div>
    <div v-if="$q.platform.is.desktop"
         class="header__right"
         @wheel.prevent="">
      <Notification />
      <Alert />
      <AccountMenu />
    </div>
  </q-layout-header>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import OasisLogo from '@/app/components/ui/logos/oasis-logo.vue';
import AccountMenu from '@/app/components/layout/header/account-menu.vue';
import Notification from '@/app/components/layout/header/notification.vue';
import Alert from '@/app/components/layout/header/alert.vue';
import HeaderMenu from '@/app/components/layout/header/header-menu.vue';
import DashboardPopUp from '@/app/pages/dashboard/components/dashboard-popup';

export default {
  name: 'Header',
  components: {
    OasisLogo,
    AccountMenu,
    Notification,
    Alert,
    HeaderMenu,
    DashboardPopUp,
  },
  data() {
    return {
      oqlMenu: [
        {
          label: 'Query Builder',
          to: { name: 'builder' },
        },
        {
          label: 'OQL query',
          to: { name: 'query' },
        },
      ],
    };
  },
  computed: {
    ...mapState('ui', {
      viewerMode: state => state.viewerMode,
    }),
    currentFloorIndex() {
      return this.$store.state.building.selectedFloor;
    },
    currentFloors() {
      return this.$store.state.building.selectedBuilding.next || null;
    },
    user() {
      return this.$store.state.user.user;
    },
    selectedBuilding() {
      return this.$store.state.building.selectedBuilding;
    },
    currentFloorName() {
      const floors = this.currentFloors;
      const currentIndex = this.currentFloorIndex;
      return floors && floors[currentIndex] ? floors[currentIndex].name : '';
    },
    dataRights() {
      return this.user.featuresRights.data;
    },
    oqlRights() {
      return this.user.featuresRights.oql;
    },
    ticketRights() {
      return this.user.featuresRights.ticket;
    },
    dashboardRights() {
      return this.user.featuresRights.dashboard;
    },
    nodesGroupsRight() {
      return this.user.featuresRights.nodesGroups;
    },
    devicesControlRight() {
      return this.user.featuresRights.devicesControl;
    }
  },
  methods: {
    clearCurrentBuilding() {
      this.$store.commit('building/setSelectedBuilding', null);
      this.setNavigationMode();
    },
    ...mapMutations('ui', {
      setViewerMode: 'SET_VIEWER_MODE',
      setSidebarInState: 'SET_SIDEBARIN_STATE',
      setSidebarOutState: 'SET_SIDEBAROUT_STATE',
    }),
    setNavigationMode() {
      if (this.viewerMode === 'deviceEdition') {
        this.setViewerMode('navigation');
        this.setSidebarInState(true);
        this.setSidebarOutState('small');
      }
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.header
  .header__left, .header__center, .header__right
    display flex
    align-items center
    height 100%

.header
  position fixed
  display flex
  align-items center
  height 50px
  background-color $dark
  box-shadow none
  @media screen and (max-width: 500px)
    position fixed
    height 35px
  .header__left
    border-right 1px solid white
    background-color black
    a
      height 100%
    @media screen and (max-width: 500px)
      width 100px
      border-right none
  .header__center
    flex 2
  .header__right
    margin-left auto
  .header__info
    width 100%
    .header__info__building-name
      margin-left 12px
      padding-top 2px
      color white
      text-transform uppercase
    .floor-level__label
      position relative
      display flex
      justify-content center
      align-items center
      margin-right 12px
      margin-left 24px
      padding 2px 8px
      border-top-right-radius 10px
      border-bottom-right-radius 10px
      background-color $black
      color white
      font-weight 500
      font-size $fs-body
      &:after
        position absolute
        top 0
        bottom 0
        left -9px
        margin 0
        padding 0
        width 0px
        height 0px
        border-top 9.5px solid transparent
        border-right 9.5px solid $black
        border-bottom 9.5px solid transparent
        content ''

.header__link
  display flex
  justify-content center
  align-items center
  padding 0 20px // $space-3 in french
  height 100%
  border-right 1px solid white
  color white
  text-decoration none
  text-transform uppercase
  letter-spacing 1px
  font-weight $fw-light
  font-size $fs-h3
  cursor pointer
  &:hover
    background-color $light
    color $black
  &.router-link-active
    background-color white
    color black
    font-weight $fw-regular
</style>
