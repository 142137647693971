<template>
  <div class="oasis-logo">
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="-20 -20 800 500"
         class="oasis-logo__svg">
      <circle class="a"
              cx="228.5"
              cy="234.5"
              r="222.5" />
      <polygon class="a"
               points="485.4 12 742.3 457 228.5 457 485.4 12" />
    </svg>
    <span v-if="!noTitle"
          class="oasis-logo__title">
      OASIS
    </span>
  </div>
</template>

<script>
export default {
  name: 'OasisLogo',
  props: {
    noTitle: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="stylus">
@import '~variables'

.oasis-logo
  display flex
  padding 0 23px 0 22px
  height 100%
  text-decoration none
  user-select none
  &:hover
    .oasis-logo__svg
      stroke $primary
    .oasis-logo__title
      color $primary
  .oasis-logo__svg
    margin-right 8px
    width 40px
    height 100%
    border none
    fill none
    stroke white
    stroke-miterlimit 10
    stroke-width 30px
    @media screen and (max-width: 500px)
      width 25px
  .oasis-logo__title
    display flex
    align-items center
    color white
    text-decoration none
    font-weight 300
    font-size $fs-h1
    @media screen and (max-width: 500px)
      font-size $fs-h3
</style>
