<template>
  <div class="account-menu">
    <span v-if="user"
class="account-menu__title">
      {{ displayName }}
      <q-popover ref="accountMenu"
                 class="pop-over">
        <q-list class="pop-over__list"
                separator>
          <q-item :link="true"
                  class="pop-over__item user">
            <q-item-side>
              <q-item-tile avatar>
                <q-icon name="mdi-account-circle"
                        class="user__avatar"
                        size="40px"
                        @click.native="showOasisVersion" />
              </q-item-tile>
            </q-item-side>
            <q-item-main>
              <q-item-tile class="user__name"
                           label>
                {{ userName }}
              </q-item-tile>
              <q-item-tile class="user__email"
                           sublabel>
                {{ user.email }}
              </q-item-tile>
            </q-item-main>
          </q-item>
          <q-item v-if="user.featuresRights.buildingConfig.WRITE"
                  :link="true"
                  class="pop-over__item t_pop-over--building"
                  @click.native="toggleUploadModal()">
            <q-item-main>
              {{$t('components.accountMenu.addBuilding')}}
              <UploadModal :show="showUploadModal"
                           @close="showUploadModal = false" />
            </q-item-main>
          </q-item>
          <q-item v-if="user.featuresRights.store.READ"
                  :link="true"
                  :to="{name:'store'}"
                  class="pop-over__item t_pop-over--store">
            {{$t('components.accountMenu.store')}}
          </q-item>
          <q-item class="pop-over__item pop-over__item--lang">
            <q-item-main>
              changer de langue
              <q-select v-model="$i18n.locale"
                        :options="langOptions"
                        class="langage-select"
                        invertedLight
                        color="white"
                        @input="setLocale" />
            </q-item-main>
          </q-item>
          <q-item v-if="user.featuresRights.admin.READ"
                  :link="true"
                  :to="{name:'admin'}"
                  class="pop-over__item t_pop-over--admin">
            Admin
          </q-item>
          <q-item :link="true"
                  class="pop-over__item t_pop-over--signOut"
                  @click.native="signOut">
            <q-item-main>
              {{$t('components.accountMenu.signOut')}}
            </q-item-main>
            <q-item-side icon="fas fa-sign-out-alt" />
          </q-item>
        </q-list>
      </q-popover>
    </span>
  </div>
</template>

<script>
import moment from 'moment';

import DebounceMixin from '@/app/mixins/debounce';

import UploadModal from '@/app/views/ifc-upload/modal.vue';
import { version } from '@/../package.json';

export default {
  name: 'AccountMenu',
  components: {
    UploadModal,
  },
  mixins: [DebounceMixin],
  data() {
    return {
      showUploadModal: false,
      currentLang: 'fr',
      langOptions: [{ value: 'fr', label: 'Francais' }, { value: 'en', label: 'English' }],
      versionClicks: 0,
      version
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    userName() {
      if (this.user.firstName && this.user.lastName) return `${this.user.firstName} ${this.user.lastName}`;
      else if (!this.user.firstName && !this.user.lastName) return `${this.user.email}`;
      else if (!this.user.firstName) return `${this.user.lastName}`;
      return `${this.user.firstName}`;
    },
    displayName() {
      if(this.user.firstName) return `${this.user.firstName}`;
      else if(this.user.lastName) return `${this.user.lastName}`;
      return `${this.user.email.split('@')[0]}`;
    },
  },
  methods: {
    showOasisVersion() {
      this.versionClicks++;
      this.debounce(() => this.versionClicks = 0, 500);
      if (this.versionClicks >= 5) {
        this.$q.notify({ message: `Oasis v${this.version}`, type: 'positive', position: 'bottom-left' });
        this.versionClicks = 0;
      }
    },
    setLocale(newLocale) {
      this.$i18n.locale = newLocale;
      moment.locale(newLocale);
    },
    toggleUploadModal() {
      this.showUploadModal = !this.showUploadModal;
      if (this.showUploadModal) this.$refs.accountMenu.hide();
    },
    async signOut() {
      try {
        await this.$store.dispatch('user/logout');
        this.$router.push({ name: 'login' });
      } catch (error) {
        this.$q.notify({ message: `An error occured when loggin out`, type: 'negative', position: 'bottom-left' });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.account-menu
  display flex
  align-items center
  height 100%
  color white
  text-transform uppercase
  font-size $fs-h2
  cursor pointer
  .account-menu__title
    display flex
    align-items center
    padding 0 20px
    height 100%
    &:hover
      background-color darken($dark, 20%)

.pop-over__item--lang >>> .q-item-main
  display flex
  align-items center
  .langage-select
    margin-left auto
    width fit-content

.pop-over
  right 0 !important
  left unset !important
  width 350px
  box-shadow none
  .pop-over__list
    padding 0
    background $dark
    .pop-over__item
      padding 16px
      box-shadow none
      color white
      text-transform uppercase
      font-size $fs-h2
      &:hover
        background-color darken($dark, 20%)
        color white
      &.router-link-active
        background-color inherit
        &:hover
          background-color darken($dark, 20%)
      &.user
        background-color black
        cursor default
        .user__avatar
          color white
        .user__name
          text-transform capitalize
          font-size $fs-body
        .user__email
          color $silver
          text-transform none
          letter-spacing 0.3px
          font-size $fs-body
</style>
