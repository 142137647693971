<template>
<div>
  <div class="notification">
      <q-item class="row"
           @click.native="navigate(actionLink)">
            <div v-if="notificationTime"
                  class="title">
            {{ title }}
            </div>
            <div v-if="notificationTime"
                  class="time">
                {{ $t('components.duration.since') }}
                {{ notificationMoment.timeValue }} <span v-if="notificationMoment.unit"> {{ $t(`components.duration.${notificationMoment.unit}`)}} </span>
            </div>
      </q-item>

  </div>
</div>
</template>

<script>
import moment from 'moment';
import DebounceMixin from '@/app/mixins/debounce';

export default {
  name: 'NotificationItem',
  mixins: [DebounceMixin],
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    actionLink: {
      type: String,
      default: ''
    },
    dateRead: {
      type: String,
      default: ''
    },
    notificationTime: {
      type: String,
      default: ''
    }
  },
  computed: {
    notificationMoment() {
      const notificationTime = this.notificationTime;
      const to = moment();
      const from = moment(notificationTime);

      const differenceInSeconds = to.diff(from, 'seconds');
      if (differenceInSeconds < 60 ) {
        return {
          timeValue: differenceInSeconds,
          unit: 'second',
        };
      } else if (differenceInSeconds < (60 * 60)) {
        const minutes = Math.floor(differenceInSeconds/60);
        return {
          timeValue: minutes,
          unit: 'minute',
        };
      } else if (differenceInSeconds < (60 * 60 * 24)) {
        const hours = Math.floor(differenceInSeconds/(60 * 60));
        return {
          timeValue: hours,
          unit: 'hour',
        };
      } else if (differenceInSeconds < (60 * 60 * 24 * 7)) {
        const days = Math.floor(differenceInSeconds/(60 * 60 * 24));
        return {
          timeValue: days,
          unit: 'day',
        };
      } else {
        const notificationDate = from.format('DD-MM-YYYY');
        return {
          timeValue: notificationDate,
          unit: '',
        };
      }
    },
  },
  methods: {
    navigate(actionLink) {
      if (!actionLink)
      {
        if (this.$route.name !== 'notifications') this.$router.push({ name: 'notifications'});
      } else {
        window.open(actionLink, '_blank');
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.notification
  display flex
  height 100%
  font-weight 600
  font-size $fs-h2
  box-shadow none
  .row
    width 100%
    color black
    background-color white
  .title
    width 75%
  .time
    width 25%

</style>
