<template>
  <div class="dashboard-menu">
      DASHBOARDS
      <q-popover ref="dashboardPopUp"
                 class="pop-over">
        <q-list v-if="dashboards && dashboards.length"
                class="pop-over__list"
                separator>
          <div v-if="rights.WRITE"
               class="dashboard-command">
            <span class="title">{{ $t('pages.dashboard.popUp.create') }}</span>
            <button class="dashboard-add-button"
                      @click="toggleAddModal()"> + </button>
              <AddDashboard :show="showAddModal"
                           @close="showAddModal = false"
                           @mainClick="getDashboardName"/>
          </div>
          <q-item v-for="(dashboard, idx) in dashboards"
                  :key="idx"
                  class="dashboard-item"
                  :class="currentRouteIsDashboard && currentDashboardId === dashboard.id ? 'selected' : ''"
                  @click.native="navigate(dashboard.id)">
            <q-item-main class="main">
              {{dashboard.name}}
            </q-item-main>
          </q-item>
        </q-list>
        <q-list v-else
                class="pop-over__list">
          <div v-if="rights.WRITE">
            <div class="dashboard-command">
              <span>{{ $t('pages.dashboard.popUp.create') }}</span>
            <button class="dashboard-add-button"
                      @click="toggleAddModal()"> + </button>
              <AddDashboard :show="showAddModal"
                           @close="showAddModal = false"
                           @mainClick="getDashboardName"/>
            </div>
            <div class="dashboard-item"> {{ $t('pages.dashboard.popUp.noDashboard') }} </div>
          </div>
          <div v-else>
            <div class="dashboard-item"> {{ $t('pages.dashboard.popUp.requestDashboard') }} </div>
          </div>
        </q-list>
      </q-popover>
  </div>
</template>

<script>
import DebounceMixin from '@/app/mixins/debounce';
import AddDashboard from '@/app/pages/dashboard/components/add-dashboard.vue';

export default {
  name: 'DashboardPopup',
  components: {
    AddDashboard
  },
  mixins: [DebounceMixin],
  data() {
    return {
      showAddModal: false,
      dashboardName: '',
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    rights() {
      return this.user.featuresRights.dashboard;
    },
    dashboards() {
      return this.$store.state.dashboard.collection;
    },
    currentDashboardId() {
      return this.$store.state.dashboard.currentDashboardId;
    },
    currentRouteIsDashboard() {
      return this.$route.name === 'dashboard';
    },
  },
  created() {
    this.fetchDashboards();
  },
  methods: {
    async fetchDashboards() {
      await this.$store.dispatch('dashboard/fetch');
    },
    navigate(dashboardId) {
      this.$store.commit('dashboard/setCurrentDashboardId', dashboardId);
      if (this.$route.name !== 'dashboard') {
        this.$router.push({ name: 'dashboard' });
      }
    },
    async createDashboard() {
      const newDashboard = { name: this.dashboardName };
      try {
        await this.$store.dispatch('dashboard/createDashboard', newDashboard);
        this.fetchDashboards();
        if (!this.currentRouteIsDashboard) {
          this.$router.push({ name: 'dashboard' });
        }
        this.$q.notify({ message: this.$t('pages.dashboard.toasts.dashboardCreated'), type: 'positive', position: 'bottom-left' });
      } catch (error) {
        this.$q.notify({ message: this.$t('pages.dashboard.toasts.errorDashboardCreation'), type: 'negative', position: 'bottom-left' });
      }
    },
    toggleAddModal() {
      this.showAddModal = !this.showAddModal;
      if (this.showAddModal) this.$refs.dashboardPopUp.hide();
    },
    getDashboardName(name) {
      this.dashboardName = name;
      this.toggleAddModal();
      this.createDashboard();
    }
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.dashboard-menu
  display flex
  height 100%
  color white
  font-size $fs-h2
  cursor pointer
  display flex

.pop-over
  width 350px
  .pop-over__list
    padding 0
    border none!important
    background $dark
    .dashboard-item
      height 100%
      font-weight 600
      font-size $fs-h2
      background white
      box-shadow none
      cursor pointer
    .selected
      background-color gray

.dashboard-command
  display flex
  padding $space-1
  color white
  letter-spacing 1px
  font-weight 300
  .title
    font-size $fs-h2
    cursor default
    display flex
    align-items center
  .dashboard-add-button
    background-color white
    margin-left auto
    align-items center
    font-size $fs-h1
    cursor pointer
    &:hover
      background-color lighten(black, 20%)
      color white

.dashboard-modal
  >>> .modal-content
    display flex
    flex-direction column
    overflow hidden
    padding 16px
    width 900px
</style>
