<template>
  <div v-if="user.featuresRights.notifications.READ"
      class="notification-menu">
      <div class="icon-container">
        <q-item class="far notification-icon"
                      :class="isEmpty ? 'fa-envelope-open empty' : 'fa-envelope not-empty'" />
        <span
          v-if="!isEmpty"
          class="icon-badge"
        >
          {{ badgeText }}
        </span>
      </div>
      <q-popover class="pop-over">
        <q-list v-if="notifications && notifications.length > 0"
                class="pop-over__list"
                separator>
          <NotificationItem v-for="notification in limitedNotifications"
               :key="notification.id"
               :title="notification.title"
               :body="notification.body"
               :actionLink="notification.actionLink"
               :dateRead="notification.dateRead"
               :notificationTime="notification.createdAt"
               class="items"
               @click.native="notification.dateRead ? '' : readNotification(notification.id) " />
          <div v-if="isMoreThanLimit"
               class="view-more"
               @click="openNotificationCenter">
            {{ $t('pages.notification.popUp.seeMore') }}
          </div>
        </q-list>
        <q-list v-else
                class="empty">
          {{ $t('pages.notification.popUp.noUnread') }}
        </q-list>
        <div class="notification-center">
          <button class="notification-button"
                  @click="openNotificationCenter">
                  {{$t('pages.notification.popUp.center')}}
          </button>
        </div>
      </q-popover>
  </div>
</template>

<script>
import DebounceMixin from '@/app/mixins/debounce';
import NotificationItem from '@/app/pages/data/components/notification-item.vue';

export default {
  name: 'Notification',
  components: {
    NotificationItem,
  },
  mixins: [DebounceMixin],
  props: {
    limit: {
      type: Number,
      default: 10,
    }
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    notifications() {
      const notifications = this.$store.state.notification.collection;
      return notifications.filter(notification => notification.dateRead === null).reverse();
    },
    limitedNotifications() {
      return this.notifications.slice(0, this.limit);
    },
    totalNotifications() {
      return this.notifications.length;
    },
    isMoreThanLimit() {
      return this.totalNotifications > this.limit;
    },
    isEmpty() {
      return this.notifications?.length === 0;
    },
    badgeText() {
      if (this.isEmpty) {
        return '';
      } else if (this.notifications.length <= 10) {
        return this.notifications.length;
      } else {
        return '10+';
      }
    }
  },
  created() {
    if (this.user.featuresRights.notifications.READ) {
      this.fetchNotifications();
    }
  },
  methods: {
    async readNotification(notificationId) {
      await this.$store.dispatch('notification/setReadStatusById', { notificationId, readStatus: true });
    },
    async fetchNotifications() {
      await this.$store.dispatch('notification/fetch');
    },
    openNotificationCenter() {
      if (this.$route.name !== 'notifications') {
        this.$router.push({ name: 'notifications'});
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~variables'

.notification-menu
  display flex
  height 100%
  color white
  font-size $fs-h2
  cursor pointer
  display flex
  .icon-container
    position relative
    &:hover
      .notification-icon, .icon-badge
        background-color darken($dark, 20%)
    .notification-icon
      display flex
      padding 0 20px
      height 100%
      font-size 25px
    .not-empty
      color $light
    .empty
      color $grey
    .icon-badge
      position absolute
      bottom 3px
      left 38px
      color $light
      background $dark
      border-radius 10px
      padding 4px
      font-size $fs-body

.pop-over
  right 0 !important
  width 500px
  box-shadow none
  .pop-over__list
    padding 0
    background $dark
    .items
      cursor pointer
    .view-more
      text-align center
      padding 10px
      height 40px
      font-weight 600
      font-size $fs-h2
      box-shadow none
      cursor pointer
      background-color white
      color $dark
      &:hover
        background-color $dark
        color white
  .notification-center
    display flex
    background  $dark
    height 40px
    .notification-button
      margin-left auto
      height 100%
      cursor pointer
  .empty
    text-align center
    padding-top 20px
    height 65px
    font-weight 600
    font-size $fs-h2
    box-shadow none
    color Black

</style>
