






















































































import Vue from 'vue';
import FetchableData from '@/app/components/ui/FetchableData.vue';
import { HttpMetadata } from '@/types/http';
import { QTableColumn } from '@/types/quasar';
import { Alert } from '@/types/api';
import { AlertsStoreState } from '@/services/store/modules/alerts';
import moment, { Moment } from 'moment';

interface AlertTableData extends Pick<Alert, ('title' | 'urgence' | 'createdAt' | 'id')> {
  localisation: string | null | undefined;
}

export default Vue.extend({
  name: 'Alert',
  components: {
    FetchableData,
  },
  props: {
    limit: {
      type: Number,
      default: 10,
    }
  },
  data(): {momentNow: Moment, momentNowUpdateTimerId: number | null} {
    return {
      // the value of moment(), updated every second by a timer
      momentNow: moment(),
      // timerId for momentNow setInterval update, needed to stop it when destroying the component
      momentNowUpdateTimerId: null,
    };
  },
  computed: {
    // FIXME used to simplify the typing of this.$store.state.alert, but will be useless once we type the vuex store (https://next.vuex.vuejs.org/guide/typescript-support.html)
    alertStore(): AlertsStoreState {
      return this.$store.state.alert;
    },
    user(): any {
      return (this.$store as any).state.user.user;
    },
    alertsMeta(): HttpMetadata {
      return this.alertStore.meta;
    },
    unresolvedAlerts(): Alert[] {
      const alerts = this.alertStore.collection;
      return alerts.filter(a => a.dateResolved === null).reverse();
    },
    limitAlerts(): Alert[] {
      return this.unresolvedAlerts.slice(0, this.limit);
    },
    totalAlerts(): number {
      return this.unresolvedAlerts.length;
    },
    hasUnresolvedAlerts(): boolean {
      return this.totalAlerts > 0;
    },
    isMoreThanLimit(): boolean {
      return this.totalAlerts > this.limit;
    },
    alertsData(): AlertTableData[] {
      const data = this.limitAlerts.map(alert => {
        return {
          title: alert.title,
          localisation: alert.roomName,
          urgence: alert.urgence,
          createdAt: alert.createdAt,
          id: alert.id,
        };
      });
      return data;
    },
    tableColumns(): QTableColumn[] {
      return [
        {
          name: 'title',
          field: 'title',
          required: true,
          label: this.capitalize(`${this.$t('pages.alert.labels.title')}`),
          align: 'center',
        },
        {
          name: 'localisation',
          field: 'localisation',
          required: false,
          label: this.capitalize(`${this.$t('pages.alert.labels.localisation')}`),
          align: 'center',
        },
        {
          name: 'urgence',
          field: 'urgence',
          required: true,
          label: this.capitalize(`${this.$t('pages.alert.labels.priority')}`),
          align: 'center',
        },
        {
          name: 'createdAt',
          field: 'createdAt',
          required: true,
          label: this.capitalize(`${this.$t('pages.alert.labels.activeSince')}`),
          align: 'center',
        }
      ];
    },
    pagination(): {rowsPerPage: number} {
      return {
        rowsPerPage: this.limit,
      };
    },
  },
  async created() {
    await this.fetchAlerts();
    // momentNow has to be updated every second so that activeSince is updated as well
    this.momentNowUpdateTimerId = setInterval(() => {
      this.momentNow = moment();
    }, 1000);
  },
  destroyed() {
    if (this.momentNowUpdateTimerId !== null) {
      clearInterval(this.momentNowUpdateTimerId);
      this.momentNowUpdateTimerId = null;
    }
  },
  methods: {
    async fetchAlerts() {
      await this.$store.dispatch('alert/fetch');
    },
    openAlertCenter() {
      if (this.$route.name !== 'alerts') {
        this.$router.push({ name: 'alerts'});
      }
    },
    openAlertRuleCenter() {
      if (this.$route.name !== 'AlertRulesCenter') {
        this.$router.push({ name: 'AlertRulesCenter'});
      }
    },
    activeSince(createdAt: string): string {
      return `${moment(createdAt).from(this.momentNow, true)}`;
    },
    capitalize(s: string): string {
      if (!s || s.length === 0) return s;
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    // navigate to alertsDetails page
    navigate(alertId: string) {
      if (this.$route.name === 'alertDetails'
        && this.$route.params.alertId === alertId) {
        return;
      }
      this.$router.push({ name: 'alertDetails', params: { alertId: alertId } });
    }
  },
});
